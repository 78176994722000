import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export interface PaymentConfigData {
  paymentConfig: {
    stripe?: {
      public_key: string;
    };
  };
}

export const PAYMENT_CONFIG_QUERY: DocumentNode = gql`
  query paymentConfig {
    paymentConfig {
      stripe {
        public_key
      }
    }
  }
`;

export const PAYMENT_METHODS_QUERY: DocumentNode = gql`
  query paymentMethods {
    paymentMethods {
      id
      card {
        exp_month
        exp_year
        last_digits
      }
    }
  }
`;

export const PAYMENT_INTENT_QUERY: DocumentNode = gql`
  query paymentIntent($id: Int!) {
    paymentIntent(id: $id) {
      status
    }
  }
`;

export const MEAL_VOUCHER_CARD_QUERY = gql`
  query {
    mealVoucherCard
      @rest(type: "MealVoucherCard", path: "/payments/meal-voucher-card/") {
      provider
      email
      number
      expiration
      bank
      instrument @type(name: "PaygreenInstrument") {
        platform
      }
    }
  }
`;

export const EDENRED_AUTH_FORM: DocumentNode = gql`
  query edenredAuthForm {
    edenredAuthForm {
      url
    }
  }
`;
